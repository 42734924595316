/*
 * Copyright 2020 Spotify AB
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import {
  Entity,
  DEFAULT_NAMESPACE,
  RELATION_OWNED_BY,
  ANNOTATION_VIEW_URL,
} from '@backstage/catalog-model';
import {
  entityRouteRef,
  FavoriteEntity,
  getEntityRelations,
  useAsyncEntity,   
} from '@backstage/plugin-catalog-react';
import {Box, Grid} from '@material-ui/core';
import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { UnregisterEntityDialog } from '../UnregisterEntityDialog/UnregisterEntityDialog';
// import {EntityRefLinks} from '../EntityRefLink';
import { Tabbed } from './Tabbed';
import {PageLayout, HeaderLabel, Content} from 'tmna-ui-kit';
import GithubIcon from '@material-ui/icons/GitHub';
import {makeStyles} from '@material-ui/core/styles';
import {EntityContextMenu} from '../EntityContextMenu/EntityContextMenu';
import {EntityRefNames} from '../EntityRefNames';

import { IconComponent, useRouteRef, useRouteRefParams } from '@backstage/core-plugin-api';
import { Link, Progress, ResponseErrorPanel, WarningPanel } from '@backstage/core-components';
import { catalogPlugin } from '../../plugin';

const useStyles = makeStyles({
  headerLink: {
    color: 'inherit',
  }
});

const EntityPageTitle = React.memo(({
  entity,
  title,
}: {
  title: string | JSX.Element;
  entity?: Entity;
}) => (
    <Box display="inline-flex" alignItems="center" height="1em">
      {title}
      {entity && <FavoriteEntity entity={entity} />}
    </Box>
));

const EntityLabels = ({ entity }: { entity: Entity }) => {
  const classes = useStyles();
  const ownedByRelations = getEntityRelations(entity, RELATION_OWNED_BY);
  const entityType = entity?.spec?.type;
  const viewUrl = entity.metadata.annotations?.[ANNOTATION_VIEW_URL];

  return (
    <Grid container spacing={4} wrap="nowrap" alignItems="center">
      {entityType && (
          <Grid item>
            <HeaderLabel
                label="Component"
                value={entityType}
            />
          </Grid>
      )}
      {ownedByRelations.length > 0 && (
        <Grid item>
          <HeaderLabel
              label="Owner"
              value={
                // <EntityRefLinks
                //     entityRefs={ownedByRelations}
                //     defaultKind="Group"
                //     type="text"
                //     className={classes.headerLink}
                // />
                <EntityRefNames entityRefs={ownedByRelations} />
              }
          />
        </Grid>
      )}
      {viewUrl && (
        <Grid item>
          <Link to={viewUrl} target="_blank" className={classes.headerLink}>
            <GithubIcon/>
          </Link>
        </Grid>
      )}
    </Grid>
  );
};

const headerProps = (
  kind: string,
  namespace: string | undefined,
  name: string,
  entity: Entity | undefined,
): { headerTitle: string; headerType: string } => {
  return {
    headerTitle: `${name}${
      namespace && namespace !== DEFAULT_NAMESPACE
        ? ` in ${namespace}`
        : ''
    }`,
    headerType: (() => {
      let t = kind.toLocaleLowerCase('en-US');
      if (entity && entity.spec && 'type' in entity.spec) {
        t += ' — ';
        t += (entity.spec as { type: string }).type.toLocaleLowerCase('en-US');
      }
      return t;
    })(),
  };
};

// NOTE(freben): Intentionally not exported at this point, since it's part of
// the unstable extra context menu items concept below
type ExtraContextMenuItem = {
  title: string;
  Icon: IconComponent;
  onClick: () => void;
};

// unstable context menu option, eg: disable the unregister entity menu
type contextMenuOptions = {
  disableUnregister: boolean;
};

type EntityPageLayoutProps = {
  UNSTABLE_extraContextMenuItems?: ExtraContextMenuItem[];
  UNSTABLE_contextMenuOptions?: contextMenuOptions;
  children?: React.ReactNode;
};

export const EntityPageLayout = ({
  UNSTABLE_extraContextMenuItems,
  UNSTABLE_contextMenuOptions,
  children,
}: EntityPageLayoutProps) => {
  const { kind, namespace, name } = useRouteRefParams(entityRouteRef);
  const { entity, loading, error } = useAsyncEntity();
  const { headerTitle } = headerProps(
    kind,
    namespace,
    name,
    entity!,
  );
  const getMainPageRoute = useRouteRef(catalogPlugin.routes.catalogIndex);

  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const navigate = useNavigate();
  const cleanUpAfterRemoval = async () => {
    setConfirmationDialogOpen(false);
    navigate('/');
  };

  const backToLink = React.useMemo(() => ({
    label: 'Back to App Catalog',
    to: getMainPageRoute(),
  }), [getMainPageRoute]);

  const showRemovalDialog = React.useCallback(() => setConfirmationDialogOpen(true), []);

  const headerAdditionalControls = React.useMemo(() => (
      <Grid container spacing={2} wrap="nowrap" alignItems="center">
        {entity && <Grid item>
          <EntityLabels entity={entity} />
        </Grid>}
        <Grid item>
          <EntityContextMenu
            UNSTABLE_extraContextMenuItems={UNSTABLE_extraContextMenuItems}
            UNSTABLE_contextMenuOptions={UNSTABLE_contextMenuOptions}
            onUnregisterEntity={showRemovalDialog}
          />
        </Grid>
      </Grid>
  ), [entity, UNSTABLE_extraContextMenuItems, showRemovalDialog]);

  return (
    <PageLayout
        type="catalog"
        title={<EntityPageTitle title={headerTitle} entity={entity}/>}
        headerBackgroundImg={'/assets/service-catalog_bg.png'}
        backToLink={backToLink}
        headerAdditionalControls={headerAdditionalControls}
        displayButton={true}
    >
      {loading && (
        <Content>
          <Progress />
        </Content>
      )}

      {entity && <Tabbed.Layout>{children}</Tabbed.Layout>}

      {error && (
        <Content>
          <ResponseErrorPanel error={error} />
        </Content>
      )}

      {!loading && !error && !entity && (
        <Content>
          <WarningPanel title="Entity not found">
            There is no {kind} with the requested{' '}
            <Link to="https://backstage.io/docs/features/software-catalog/references">
              kind, namespace, and name
            </Link>
            .
          </WarningPanel>
        </Content>
      )}

      <UnregisterEntityDialog
        open={confirmationDialogOpen}
        entity={entity!}
        onConfirm={cleanUpAfterRemoval}
        onClose={() => setConfirmationDialogOpen(false)}
      />
    </PageLayout>
  );
};

EntityPageLayout.Content = Tabbed.Content;
