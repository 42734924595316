/*
 * Copyright 2020 Spotify AB
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import GithubIcon from '@material-ui/icons/GitHub';
import { CompoundEntityRef, RELATION_OWNED_BY } from '@backstage/catalog-model';
import { Link } from '@backstage/core-components';
import { useRouteRef } from '@backstage/core-plugin-api';
import { Header } from 'tmna-ui-kit';
import { TechDocsEntityMetadata, TechDocsMetadata } from '../../types';
import { EntityRefLinks, entityRouteRef, getEntityRelations } from '@backstage/plugin-catalog-react';
import { rootRouteRef } from '../../routes';
import { HeaderLabel } from './HeaderLabel';

const useStyles = makeStyles(({

  header: {
    width: '400px',
    color:'white'
  }

}));

type TechDocsPageHeaderProps = {
  entityId: CompoundEntityRef;
  entityMetadata?: TechDocsEntityMetadata;
  techDocsMetadata?: TechDocsMetadata;
};

export const TechDocsPageHeader = ({
  entityId,
  entityMetadata,
  techDocsMetadata,
}: TechDocsPageHeaderProps) => {
  const { name } = entityId;

  const { site_name: siteName } =
    techDocsMetadata || {};

  const classes = useStyles();

  const { locationMetadata } = entityMetadata || {};

  const componentLink = useRouteRef(entityRouteRef);

  const ownedByRelations = entityMetadata
    ? getEntityRelations(entityMetadata, RELATION_OWNED_BY)
    : [];

  const docsRootLink = useRouteRef(rootRouteRef)();

  const labels = (
    // <Grid container wrap="nowrap" alignItems="center">
    <Grid container wrap="nowrap" className={classes.header}>
      <HeaderLabel
        label="Component"
        value={
          <Link style={{ color: 'white' }} to={componentLink(entityId)}>
            {name}
          </Link>
        }
      />
      {ownedByRelations.length > 0 && (
        <HeaderLabel
          label="Owner"
          value={
            <EntityRefLinks
              // color="inherit"
              style={{ color: 'white' }}
              entityRefs={ownedByRelations}
              defaultKind="group"
            />
          }
        />
      )}
      {locationMetadata &&
        locationMetadata.type !== 'dir' &&
        locationMetadata.type !== 'file'
        ? (
          <HeaderLabel
            label=""
            value={
              <a
                href={locationMetadata.target}
                target="_blank"
                rel="noopener noreferrer"
              >
                <GithubIcon style={{ marginTop: '5px' }} />
              </a>
            }
          />
        )
        : null}
    </Grid>
  );

  const backToLink = React.useMemo(() => ({
    to: docsRootLink,
    label: 'Back to Docs'
  }), [docsRootLink]);

  return (
    <>
    <div style={{color:"white",display:"contents"}}>
    <Header
      type="entity"
      title={siteName || name}
      backToLink={backToLink}
      additionalControls={labels}
    />
    </div>
    </>
  );
};
