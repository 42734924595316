import React from 'react';
import {tmnaTheme} from 'tmna-theme';

import {apis} from './apis';
import * as plugins from './plugins';
import {AppSidebar} from './sidebar';
import {Route, Navigate} from 'react-router';
import {CatalogEntityPage, CatalogIndexPage,} from '@internal/plugin-catalog-fork';
import {TechdocsPage} from '@internal/plugin-techdocs-fork';
// TODO: re-introduce, when search engine is fixed
//  Related issue https://github.com/backstage/backstage/issues/5207
import {SearchPage} from '@backstage/plugin-search';
// TODO: uncomment when customize settings page
import {ApiExplorerPage,} from '@backstage/plugin-api-docs';
import {ScaffolderPage} from '@internal/plugin-scaffolder-fork';
import {CatalogImportPage} from '@internal/plugin-catalog-import-fork';
import {BlogsArticlePage, BlogsMainPage} from '@internal/plugin-blogs';
import {WelcomePage} from '@internal/plugin-welcome-page';
//import {CostCenterPage} from '@internal/plugin-cost-center';
import {TrainingPage, TrainingsMainPage} from '@internal/plugin-training';
import {NewsPage} from '@internal/plugin-news';
import {NotificationsPage, NotificationsProvider} from '@internal/plugin-notifications';
import {UserSettingsPage} from '@internal/plugin-user-settings-fork';

import {SignInPage} from './SignInPage';

import {EntityPage} from './components/catalog/EntityPage';
// TODO: re-introduce, when search engine is fixed
//  Related issue https://github.com/backstage/backstage/issues/5207
import {searchPage} from './components/search/SearchPage';
import {DataDogWatcher} from "./components";
import {setTokenCookie} from 'tmna-utils';

import { AlertDisplay, OAuthRequestDialog, SidebarPage } from '@backstage/core-components';
import { FlatRoutes } from '@backstage/core-app-api';
import { createApp } from '@backstage/app-defaults';
import { microsoftAuthApiRef, discoveryApiRef, useApi, IdentityApi, githubAuthApiRef } from '@backstage/core-plugin-api';
//import { CloudAccountMasterPage } from '@internal/plugin-cloud-account-master';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import {CatalogGraphPage,catalogGraphPlugin} from '@backstage/plugin-catalog-graph';
import { PlatformChargebacksPage } from '@internal/plugin-platform-chargebacks';
import { CloudAccountPage } from '@internal/plugin-cloud-account';
import { CloudAccountDetails } from '@internal/plugin-cloud-account/src/components/AccountDetails';
import { CloudChargeBackDetails } from '@internal/plugin-cloud-account/src/components/ChargeBackDetails';
import { CloudAccountEdit } from '@internal/plugin-cloud-account/src/components/CloudAccountEdit';
import { NewsLetterPage } from '@internal/plugin-news-letter';
import { ViewAllNewsLetterComponent } from '@internal/plugin-news-letter/src/components/viewAllNewsletter';
import { EdgeCost } from '@internal/plugin-cloud-account/src/components/EdgeCost';
import RadarMain from './RadarMain';
import { InsightsPage } from '@internal/plugin-insights';

const authProviders = [
  {
    id: 'microsoft-auth-provider',
    title: 'AzureAD',
    message: 'Sign in with Microsoft Azure',
    icon: 'assets/microsoft.svg',
    apiRef: microsoftAuthApiRef,
  }
];

const app = createApp({
  apis,
  plugins: Object.values(plugins),
  bindRoutes({ bind }) {
    bind(plugins.CatalogPlugin.externalRoutes, {
      createComponent: plugins.ScaffolderPlugin.routes.root,
    });
    bind(catalogGraphPlugin.externalRoutes, {
      catalogEntity: plugins.CatalogPlugin.routes.catalogEntity as any,
    });
    bind(plugins.ApiDocs.externalRoutes, {
      registerApi: plugins.ScaffolderPlugin.routes.root,
    });
    bind(plugins.WelcomePagePlugin.externalRoutes, {
      blueprintsLink: plugins.ScaffolderPlugin.routes.root,
      portalOverviewLink: plugins.TrainingPlugin.routes.trainingPage,
      docsLink: plugins.TechDocsPlugin.routes.root,
    });
    bind(plugins.TechDocsPlugin.externalRoutes, {
      createComponentLink: plugins.ScaffolderPlugin.routes.root,
    });
    bind(plugins.CatalogImport.externalRoutes, {
      blueprintsLink: plugins.ScaffolderPlugin.routes.root,
    });
    bind(plugins.ScaffolderPlugin.externalRoutes, {
      registerComponent: plugins.CatalogImport.routes.importPage,
    });
    bind(plugins.CatalogPlugin.externalRoutes, {
      registerComponent: plugins.CatalogImport.routes.importPage,
    });
    bind(plugins.NotificationsPlugin.externalRoutes, {
      home: plugins.WelcomePagePlugin.routes.welcome,
    })
    bind(plugins.NewsPlugin.externalRoutes, {
      home: plugins.WelcomePagePlugin.routes.welcome,
    });
  },
  components: {
    SignInPage: props => {
      const discoveryApi = useApi(discoveryApiRef);
      const githubAuthApi = useApi(githubAuthApiRef);
      // const authref = useApi(microsoftAuthApiRef);
      return (<SignInPage
        {...props}
        providers={[...authProviders]}
        onSignInSuccess={async (identityApi: IdentityApi) => {
          await githubAuthApi.getProfile({ optional: true });
          // When logged in, set a token cookie
          // if (typeof await identityApi.getCredentials() !== 'undefined') {
          //   const baseURL = await discoveryApi.getBaseUrl('cookie');
          //   await setTokenCookie(
          //     baseURL,
          //     identityApi,
          //   );
          // }
          // authref.getAccessToken().then((token: any) => {
          //   fetch("https://graph.microsoft.com/beta/me/profile/", {
          //     headers: {
          //       "Content-Type": "application/json",
          //       "ConsistencyLevel": "eventual",
          //       'Authorization': 'Bearer ' + token
          //     },
          //   }).then(response => response.json()).then(data => console.log('logged in user info ' + JSON.stringify(
          //     {
          //       name: data?.names ? data?.names[0]?.displayName : "",
          //       jobTitle: data?.positions ? data?.positions[0]?.detail?.jobTitle : "",
          //       department: data?.positions ? data?.positions[0]?.detail?.company?.department : ""
          //     })))
          // });
          setTokenCookie(
            await discoveryApi.getBaseUrl('cookie'),
            identityApi
          );          
          // Forward results
          props.onSignInSuccess(identityApi);
        }}
      />);
    }
  },
  themes: [{
    id: 'toyota',
    title: 'Toyota Theme',
    variant: 'light',
    Provider: ({ children }) => (
      <ThemeProvider theme={tmnaTheme}>
        <CssBaseline>{children}</CssBaseline>
      </ThemeProvider>
    ),   
  }]
});

const AppProvider = app.getProvider();
const AppRouter = app.getRouter(); 

const App = () => (
  <AppProvider>
    <AlertDisplay/>
    <OAuthRequestDialog/>
    <AppRouter>
        <SidebarPage>
          <NotificationsProvider>
            <AppSidebar/>
            <FlatRoutes>
              <Route path="/" element={<WelcomePage/>}/>
              <Route path="/catalog" element={<CatalogIndexPage initiallySelectedFilter="all"/>}/>
              <Route
                  path="/catalog/:namespace/:kind/:name"
                  element={<CatalogEntityPage/>}
              >
                <EntityPage/>
              </Route>
              <Route path="/catalog-graph" element={<CatalogGraphPage/>} />
              <Route path="/docs" element={<TechdocsPage/>}/>
              <Route path="/create" element={<ScaffolderPage/>}/>
              <Route path="/catalog-import" element={<CatalogImportPage/>}/>
              {/*
                TODO:
                  re-introduce, when search engine is fixed
                  Related issue https://github.com/backstage/backstage/issues/5207
              */}
            <Route path="/search" element={<SearchPage />}>
              {searchPage}
            </Route> 
              <Route path="/settings" element={<UserSettingsPage/>}/>
              <Route path="/welcome" element={<WelcomePage/>}/>
              <Route path="/blogs" element={<BlogsMainPage/>}/>
              <Route path="/blogs/:articleId" element={<BlogsArticlePage/>}/>
              <Route path="/cost-center" element= {<Navigate to="/cloud-account" replace />}/>
              <Route path="/training" element={<TrainingsMainPage/>}/>
              <Route path="/training/:trainingId" element={<TrainingPage/>}/>
              {/*
                Keeping these routes for now, however, links in sidebar are removed
              */}
              <Route path="/api-docs" element={<ApiExplorerPage/>}/>
              <Route path="/news" element={<NewsPage />}/>
              <Route path="/notifications" element={<NotificationsPage />}/>
              <Route path="/cloud-account-master" element={<CloudAccountDetails />}/>
              <Route path="/devops-tools-costs" element={<PlatformChargebacksPage />}/>
              <Route path="/cloud-account" element={<CloudAccountPage />}/>
              <Route path="/cloud-account/CloudAccountDetails" element={<CloudAccountDetails />}/>
              <Route path="/cloud-account/edit" element={<CloudAccountEdit isEdit={true} />}/>
              <Route path="/cloud-account/create" element={<CloudAccountEdit isEdit={false} />}/>
              <Route path="/cloud-account/CloudChargeBackDetails" element={<CloudChargeBackDetails />}/>
              <Route path="/cloud-account/edge-cost" element={<EdgeCost/>}/>
              <Route path="/newsletter" element={<NewsLetterPage />}/>
              <Route path="/newsletter/list" element={<ViewAllNewsLetterComponent />}/>
              {/* //CDX-8324 */}
              <Route path="/techradar" element={<RadarMain/>}/>
              <Route path="/insights" element={<InsightsPage />}/>
            </FlatRoutes>
          </NotificationsProvider>
        </SidebarPage>
        <DataDogWatcher />
    </AppRouter>
  </AppProvider>
);

export default App;
