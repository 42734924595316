/*
 * Copyright 2020 Spotify AB
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {
  ContentHeader,
  //CreateButton,
  TableColumn,
} from '@backstage/core-components';
import { useRouteRef } from '@backstage/core-plugin-api';
import {
  EntityLifecyclePicker,
  EntityListProvider,
  EntityOwnerPicker,
  UserListFilterKind,
  EntityKindPicker,
  EntityTypePicker,
} from '@backstage/plugin-catalog-react';
import React from 'react';
import { Box, makeStyles, Typography, Button } from '@material-ui/core';
//import CatalogIcon from '@material-ui/icons/Widgets';
//import VideocamIcon from '@material-ui/icons/Videocam';
import DescriptionIcon from '@material-ui/icons/Description';
import {
  PageLayout,
  SupportButton,
  //LocationTimes,
  Content,
  SupportTextList,
  SupportLinks,
  //SupportLinkCard,
  SupportMultipleLinkCard
} from 'tmna-ui-kit';
//import { createComponentRouteRef } from '../../routes';
import { registerComponentRouteRef } from '../../routes';
import { Link as RouterLink } from 'react-router-dom';
import { UserListPicker } from '../UserListPicker/UserListPicker';
import { CatalogTable } from '../CatalogTable/CatalogTable';
import { EntityTagPicker } from '../EntityTagPicker/EntityTagPicker';
import { EntityRow } from '../CatalogTable/types';
import {
  FilteredEntityLayout,
  EntityListContainer,
  FilterContainer,
} from '../FilteredEntityLayout';

const useStyles = makeStyles(theme => ({
  buttonSpacing: {
    marginLeft: theme.spacing(2),
  },
  // TODO: find better solution
  picker: {
    '& span': {
      marginBottom: theme.spacing(1),
      fontSize: '12px',
      textTransform: 'none',
    },
  },
  // TODO: find better solution
  checkboxPicker: {
    '& > div > span': {
      marginBottom: theme.spacing(1),
      fontSize: '12px',
      textTransform: 'none',
      display: 'inline-block',
    },
  },
  kindtype: {
    padding: '0%',
    width: '100%',
    marginBottom: '2%',
  },
}));

{/*
const APP_CATALOG_SUPPORT_LINK =
'/docs/default/component/ace-tech-docs/catalog/introduction/'; */}

const RELATED_DOCS_SUPPORT_LINKS =
  [
    {
      to: '/docs/default/component/ace-tech-docs/catalog/introduction/',
      title: 'What is App Catalog?',
    },
    {
      to: '/docs/default/component/ace-tech-docs/catalog/how-it-works/adding-components/',
      title: 'How does it work?',
    },
    {
      to: '/docs/default/component/ace-tech-docs/catalog/definitions/yaml-file/',
      title: 'Definitions',
    }
  ];

export type CatalogPageProps = {
  initiallySelectedFilter?: UserListFilterKind;
  columns?: TableColumn<EntityRow>[];
};

export const CatalogPage = ({
  columns,
  initiallySelectedFilter = 'owned',
}: CatalogPageProps) => {
  const styles = useStyles();

  const titleComponent = React.useCallback(
    () => <Typography variant="h2">Components</Typography>,
    [],
  );
  //const createComponentLink = useRouteRef(createComponentRouteRef);

  const registerComponentLink = useRouteRef(registerComponentRouteRef);

  return (
    <PageLayout
      title="App Catalog"
      subtitle="Centralized register for all software in TMNA"
      //headerAdditionalControls={<LocationTimes />}
      headerBackgroundImg={'/assets/service-catalog_bg.png'}
      displayButton={true}
    >
      <Content>
        <Box mb={4}>
          <ContentHeader titleComponent={titleComponent}>
            {registerComponentLink && (
              <Button
                component={RouterLink}
                variant="contained"
                color="primary"
                to={registerComponentLink()}
              >
                Register Existing Component
              </Button>
            )}
            <SupportButton>
              <SupportTextList>
                <Typography variant="body2">
                  Centralized register
                  that provides ownership and metadata related information for all software in TMNA.
                </Typography>
              </SupportTextList>
              <SupportLinks>
                <SupportMultipleLinkCard
                  icon={<DescriptionIcon />}
                  title="Related Docs"
                  items={RELATED_DOCS_SUPPORT_LINKS}
                />
              </SupportLinks>
            </SupportButton>
          </ContentHeader>
        </Box>
        <EntityListProvider>
          <FilteredEntityLayout>
            <FilterContainer>
              <div>
                <div className={styles.picker}>
                  <EntityKindPicker initialFilter="component" />
                </div>

                <div className={styles.kindtype}>
                  <EntityTypePicker />
                </div>
                <UserListPicker initialFilter={initiallySelectedFilter} />
                {/* <EntityKindPicker initialFilter="component" hidden />
                <Box mt={1}>
                  <div className={styles.picker}>
                    <EntityTypePicker />
                  </div>
                </Box> */}
                <div className={styles.checkboxPicker}>
                  <EntityOwnerPicker />
                </div>
                <div className={styles.checkboxPicker}>
                  <EntityLifecyclePicker />
                </div>
                <EntityTagPicker />
              </div>
            </FilterContainer>
            <EntityListContainer>
              <CatalogTable columns={columns} />
            </EntityListContainer>
          </FilteredEntityLayout>
        </EntityListProvider>
      </Content>
    </PageLayout>
  );
};
