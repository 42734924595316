/*
 * Copyright 2020 Spotify AB
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, { PropsWithChildren } from 'react';
// import {Entity} from '@backstage/catalog-model';
import {useEntity} from '@backstage/plugin-catalog-react';
import {Route, Routes} from 'react-router-dom';
// import {rootCatalogDocsRouteRef, rootDocsRouteRef, rootRouteRef,} from './routes';
import {LegacyTechDocsHome as TechDocsIndexPage} from './home/components/LegacyTechDocsHome';
import {TechDocsPage as TechDocsReaderPage} from './reader/components/TechDocsPage';
import {EntityPageDocs} from './EntityPageDocs';
import { MissingAnnotationEmptyState } from '@backstage/core-components';

const TECHDOCS_ANNOTATION = 'backstage.io/techdocs-ref';

export const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<TechDocsIndexPage />} />
        <Route
            path="/:namespace/:kind/:name/*"
            element={<TechDocsReaderPage />}
        />
    </Routes>
  );
};

// type Props = {
//   /** @deprecated The entity is now grabbed from context instead */
//   entity?: Entity;
// };

export const EmbeddedDocsRouter = (props: PropsWithChildren<{}>) => {
  const { children } = props;
  const { entity } = useEntity();

  const projectId = entity.metadata.annotations?.[TECHDOCS_ANNOTATION];

  if (!projectId) {
    return <MissingAnnotationEmptyState annotation={TECHDOCS_ANNOTATION} />;
  }

  return (
    <Routes>
      <Route path="/*"        
        element={<EntityPageDocs entity={entity} />}
      >{children}</Route>
    </Routes>
  );
};
