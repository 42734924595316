/*
 * Copyright 2020 Spotify AB
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {
  Entity,
  DEFAULT_NAMESPACE,
  RELATION_CONSUMES_API,
  RELATION_PROVIDES_API,
  ANNOTATION_EDIT_URL,
} from '@backstage/catalog-model';
import {
  ScmIntegrationIcon,
  scmIntegrationsApiRef,
} from '@backstage/integration-react';
import {
  getEntityRelations,
  getEntitySourceLocation,
  useEntity,
} from '@backstage/plugin-catalog-react';
import {
  Card,
  CardContent,
  CardHeader,
  Chip,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  Typography,
} from '@material-ui/core';
import DocsIcon from '@material-ui/icons/Description';
import CiIntegrationIcon from '@material-ui/icons/Build';
import EditIcon from '@material-ui/icons/Edit';
import ExtensionIcon from '@material-ui/icons/Extension';
import QualityIcon from '@material-ui/icons/HighQuality';
import React from 'react';
import { AboutContent } from './AboutContent';
import { Link } from 'tmna-ui-kit';

import {
  IconLinkVerticalProps,
  InfoCardVariants,
} from '@backstage/core-components';
import { useApi } from '@backstage/core-plugin-api';

const useStyles = makeStyles(theme => ({
  gridItemCard: {
    display: 'flex',
    flexDirection: 'column',
    //  height: 'calc(100% - 10px)', // for pages without content header
    marginBottom: '10px',
  },
  fullHeightCard: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  gridItemCardContent: {
    flex: 1,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4),
  },
  fullHeightCardContent: {
    flex: 1,
  },
}));

type IconLink = IconLinkVerticalProps & {
  isBlankTarget?: boolean;
};

type AboutCardProps = {
  /** @deprecated The entity is now grabbed from context instead */
  entity?: Entity;
  variant?: InfoCardVariants;
};

export function AboutCard({ variant }: AboutCardProps) {
  const classes = useStyles();
  const { entity } = useEntity();
  const scmIntegrationsApi = useApi(scmIntegrationsApiRef);
  const apiEndpoint:any[] = [];
  const entitySourceLocation = getEntitySourceLocation(
    entity,
    scmIntegrationsApi,
  );
  const entityMetadataEditUrl =
    entity.metadata.annotations?.[ANNOTATION_EDIT_URL];
  const providesApiRelations = getEntityRelations(
    entity,
    RELATION_PROVIDES_API,
  ); 
  const consumesApiRelations = getEntityRelations(
    entity,
    RELATION_CONSUMES_API,
  );
  const hasApis = providesApiRelations.length > 0 || consumesApiRelations.length > 0;
  if(hasApis) {
    if(Array.isArray(providesApiRelations) && providesApiRelations.length && providesApiRelations.length > 0){
      providesApiRelations.map((url:any) => {
        apiEndpoint.push(url);
      });
    }
    if(Array.isArray(consumesApiRelations) && consumesApiRelations.length && consumesApiRelations.length > 0){
      consumesApiRelations.map((url:any) => {
        apiEndpoint.push(url);
      });
    }
  }

  const viewInSource: IconLink = {
    label: 'View Source',
    disabled: !entitySourceLocation,
    icon: <ScmIntegrationIcon type={entitySourceLocation?.integrationType} />,
    href: entitySourceLocation?.locationTargetUrl,
    isBlankTarget: true,
  };
  const viewInTechDocs: IconLink = {
    label: 'View TechDocs',
    disabled: !entity.metadata.annotations?.['backstage.io/techdocs-ref'],
    icon: <DocsIcon />,
    href: `/docs/${entity.metadata.namespace || DEFAULT_NAMESPACE}/${
      entity.kind
    }/${entity.metadata.name}`,
  };
  const viewInJenkins: IconLink = {
    label: 'View CI/CD',
    disabled: !entity.metadata.annotations?.['jenkins.io/job-full-name'],
    icon: <CiIntegrationIcon />,
    href: `https://jenkins.tmna-devops.com/job/${entity.metadata.annotations?.['jenkins.io/job-full-name']}`,
  };
  const viewInSonarQube: IconLink = {
    label: 'View Quality Scans',
    disabled: !entity.metadata.sonarQubeURL,
    icon: <QualityIcon />,
    href: `${entity.metadata.sonarQubeURL}`,
  };
  const viewApi: IconLink = {
    title: hasApis ? '' : 'No APIs available',
    label: 'View API',
    disabled: !hasApis,
    icon: <ExtensionIcon />,
    href: 'api',
  };

  let cardClass = '';
  if (variant === 'gridItem') {
    cardClass = classes.gridItemCard;
  } else if (variant === 'fullHeight') {
    cardClass = classes.fullHeightCard;
  }

  let cardContentClass = '';
  if (variant === 'gridItem') {
    cardContentClass = classes.gridItemCardContent;
  } else if (variant === 'fullHeight') {
    cardContentClass = classes.fullHeightCardContent;
  }

  return (
    <>
      <Card className={cardClass}>
        <CardHeader
          title="About"
          titleTypographyProps={{ variant: 'h3' }}
          action={
            <IconButton
              aria-label="Edit"
              disabled={!entityMetadataEditUrl}
              title="Edit Metadata"
              onClick={() => {
                window.open(entityMetadataEditUrl ?? '#', '_blank');
              }}
            >
              <EditIcon />
            </IconButton>
          }
        />
        <CardContent className={cardContentClass}>
          <Grid container spacing={3}>
            {[
              viewInSource,
              viewInJenkins,
              viewInSonarQube,
              viewInTechDocs,
              viewApi,
            ].map(item => (
              item.label !== 'View API' ?
                (<Grid item key={item.href}>
                  <Link
                    to={item.href as string}
                    icon={item.icon}
                    iconPosition="left"
                    type="secondary"
                    target={item.isBlankTarget ? '_blank' : '_self'}
                    isDisabled={item.disabled}
                    spacing={1}
                  >
                    <Typography variant="button">{item.label}</Typography>
                  </Link>
                </Grid>) : 
                (<Grid item key={item.href}>
                  <Link
                    to=''
                    icon={item.icon}
                    iconPosition="left"
                    type="secondary"
                    isDisabled={item.disabled}
                    spacing={1}
                    onClick={() => {
                      if(apiEndpoint.length > 0){
                        apiEndpoint.forEach((api:any) => {
                          window.open(`/catalog/${api.namespace}/${api.kind}/${api.name}/definition`, '_blank');
                        });
                      }
                    }}
                  >
                    <Typography variant="button">{item.label}</Typography>
                  </Link>
                </Grid>)
            ))}
          </Grid>
        </CardContent>
        <Divider variant="middle" />
        <CardContent
          className={variant === 'gridItem' ? classes.gridItemCardContent : ''}
        >
          <AboutContent entity={entity} />
        </CardContent>
        {entity?.metadata?.tags?.length && (
          <>
            <Divider variant="middle" />
            <CardContent
              className={
                variant === 'gridItem' ? classes.gridItemCardContent : ''
              }
            >
              {entity?.metadata?.tags.map(t => (
                <Chip key={t} size="medium" label={t} />
              ))}
            </CardContent>
          </>
        )}
      </Card>
    </>
  );
}
