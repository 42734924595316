/*
 * Copyright 2021 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { BackstageTheme } from '@backstage/theme';
import {
    Box,
    Button,
    Drawer,
    Grid,
    Typography,
    useMediaQuery,
    useTheme,
} from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import React, { useState, PropsWithChildren } from 'react';

export const FilterContainer = ({ children }: PropsWithChildren<{}>) => {
    const isMidSizeScreen = useMediaQuery<BackstageTheme>(theme =>
        theme.breakpoints.down('sm'),
    );
    const theme = useTheme<BackstageTheme>();
    const [filterDrawerOpen, setFilterDrawerOpen] = useState<boolean>(false);

    return isMidSizeScreen ? (
        <>
            <Button
                style={{ marginTop: theme.spacing(1), marginLeft: theme.spacing(1) }}
                onClick={() => setFilterDrawerOpen(true)}
                startIcon={<FilterListIcon />}
            >
                Filters
            </Button>
            <Drawer
                open={filterDrawerOpen}
                onClose={() => setFilterDrawerOpen(false)}
                anchor="left"
                disableAutoFocus
                keepMounted
                variant="temporary"
            >
                <Box m={2}>
                    <Typography
                        variant="h6"
                        component="h2"
                        style={{ marginBottom: theme.spacing(1) }}
                    >
                        Filters
                    </Typography>
                    {children}
                </Box>
            </Drawer>
        </>
    ) : (
        <Grid item md={3}>
            {children}
        </Grid>
    );
};
