import {
    scmIntegrationsApiRef,
    ScmIntegrationsApi,
} from '@backstage/integration-react';
import {createApiFactory, configApiRef, AnyApiFactory,microsoftAuthApiRef
    ,discoveryApiRef,oauthRequestApiRef, identityApiRef,
    githubAuthApiRef} from '@backstage/core-plugin-api';
import OAuth2Custom from './SignInPage/customauth/OAuth2Custom';
import { techRadarApiRef } from '@backstage/plugin-tech-radar';
import { TechRadarClient } from './api';
import { GithubAuth } from '@backstage/core-app-api';

const DEFAULT_PROVIDER = {
    id: 'microsoft',
    title: 'Microsoft',
    icon: () => null,
  };

const defaultScopes = [
    'openid',
    'offline_access',
    'profile',
    'email',
    'User.Read',
];

export const gitHubScope = ['repo', 'workflow'];

export const apis: AnyApiFactory[] = [
    createApiFactory({
        api: scmIntegrationsApiRef,
        deps: { configApi: configApiRef },
        factory: ({ configApi }) => ScmIntegrationsApi.fromConfig(configApi),
    }),
    createApiFactory({
        api: microsoftAuthApiRef, 
        deps: {discoveryApi: discoveryApiRef,oauthRequestApi: oauthRequestApiRef},
        factory: ({discoveryApi,oauthRequestApi}) =>
        OAuth2Custom.create({discoveryApi,oauthRequestApi,provider: DEFAULT_PROVIDER,
        environment: 'development', defaultScopes: defaultScopes})
   }),
   createApiFactory({
    api: githubAuthApiRef,
    deps: {
      discoveryApi: discoveryApiRef,
      oauthRequestApi: oauthRequestApiRef,
      configApi: configApiRef,
    },
    factory: ({ discoveryApi, oauthRequestApi, configApi }) =>
      GithubAuth.create({
        configApi,
        discoveryApi,
        oauthRequestApi,
        defaultScopes: gitHubScope,
        environment: 'development',
      }),
  }),
    //CDX-8324
    createApiFactory({
        api: techRadarApiRef,
        deps: {
        configApi: configApiRef,
        identityApi: identityApiRef,
        },
        factory: ({configApi, identityApi}) =>
        new TechRadarClient({ configApi, identityApi }),
    }),
];
