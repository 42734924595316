import {
    errorApiRef,
    useApi,
    useRouteRefParams,
} from '@backstage/core-plugin-api';
import {
    catalogApiRef,
    EntityLoadingStatus,
    entityRouteRef,
} from '@backstage/plugin-catalog-react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import useAsyncRetry from 'react-use/lib/useAsyncRetry';

export const useEntityFromUrl = (): EntityLoadingStatus => {
    const { kind, namespace, name } = useRouteRefParams(entityRouteRef);
    const navigate = useNavigate();
    const errorApi = useApi(errorApiRef);
    const catalogApi = useApi(catalogApiRef);

    const {
        value: entity,
        error,
        loading,
        retry: refresh,
    } = useAsyncRetry(
        () => catalogApi.getEntityByRef({ kind, namespace, name }),
        [catalogApi, kind, namespace, name],
    );

    useEffect(() => {
        if (!name) {
            errorApi.post(new Error('No name provided!'));
            navigate('/');
        }
    }, [errorApi, navigate, error, loading, entity, name]);

    return { entity, loading, error, refresh };
};