import {
  Button,
  Grid,
  Snackbar,
  TextField,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { costCenterApiRef } from '@internal/plugin-cost-center/src/api';
import { useAsync, useAsyncFn } from 'react-use';
//import { mapBusinessUnitsToOptions } from '@internal/plugin-cost-center/src/pages/CostCenterPage/components/CloudCostCard/components/BusinessUnitTabContent/components/BusinessUnitsForm/utils';
import { IdentityApi, useApi } from '@backstage/core-plugin-api';
import LimitTagsUserDefault from '../Shared/LimitTagsUserDefault';
import { identityApiRef,microsoftAuthApiRef } from '@backstage/core-plugin-api';
import { settingsApiRef } from '../../api';
import { Alert, Color } from '@material-ui/lab';
import { useForm, Controller } from 'react-hook-form';
import { CircularProgress } from '@material-ui/core';
import { useStyles } from './styles';
import { useRef } from 'react';
export type CloudAccountFilterForm = {
  bul2: string[];
  bul3: string[];
  accountIds: string[];
  app_ser_id: string;
  gl_id: string;
  cost_center_id: string;
  wbs_id: string;
  userName:string[];
  emailID:string[];
  csp:string[];
  status:string[];
  typesOfDeployment:string[];
  typesOfAccount:string[];
};
export const UserSettingsDefault = () => {
  const costCenterApi = useApi(costCenterApiRef);
  const [l2Values, setL2Values] = React.useState([] as any);
  const [l2Selected, setL2Selected] = React.useState([] as any);
  const [l3Values, setL3Values] = React.useState([] as any);
  const [l3Selected, setL3Selected] = React.useState([] as any);
  const [customLoader, setCustomLoader] = React.useState({
    bul2Loader:false,
    bul3Loader:false,
    accountLoader:false,
    contactNameLoader:false,
    contactEmailLoader:false,
    otherFieldLoader:false,
    loaderDropdowns:false
  } as any);
  const [AccountIds, setAccountIds] = React.useState([] as any);
  const [SelectedAccountsIds, setSelectedAccountsIds] = React.useState(
    [] as any,
  );
  const [defaultData, setDefaultData] = React.useState([] as any);
  const [isAccountIdNotAvl, setIsAccountIdNotAvl] = React.useState(false);
  const [alert, setAlert] = React.useState<{
    open: boolean;
    message: string;
    severity: Color;
  }>({
    open: false,
    message: '',
    severity: 'success',
  });
  const [disableClrCancel, setDisableClrCancel] = React.useState(false);
  const [formData, setFormData] = React.useState({
    contact_name: [],
    contact_name_selected: [],
    contact_email: [],
    contact_email_selected: [],
    typesOfAccount: [],
    typesOfAccountSelected: [],
    typesOfDeployment: [],
    typesOfDeploymentSelected: [],
    csp: [],
    cspSelected: [],
    status: [],
    statusSelected: [],
  } as any);
  const identityApi: IdentityApi = useApi(identityApiRef);
  const userServiceApi = useApi(settingsApiRef);
  const authref = useApi(microsoftAuthApiRef);
  const defaultSetDataCon = useRef(true);
  const classes = useStyles();
  const fields = [
    {
      page: 'CAM',
      field_key: 'cost_center_id',
      field_label: 'Cost Center ID',
      field_type: 'single',
    },
    {
      page: 'CAM',
      field_key: 'gl_id',
      field_label: 'General Ledger ID',
      field_type: 'single',
    },
    {
      page: 'CAM',
      field_key: 'wbs_id',
      field_label: 'WBS ID',
      field_type: 'single',
    }
  ];

  const setLoader = (key:string , value:boolean) =>{
    setCustomLoader((prevData: any) => ({
      ...prevData,
      [key]:value
    }))
  }
  const handleBUL3Change =async (value: any, selectAll:boolean = false) => {
    setLoader("accountLoader",true);
    setIsAccountIdNotAvl(false);
    setAccountIds([]);
    setSelectedAccountsIds([]);
    setL3Selected(value);

    try{
      const temp = (value ?? []).map((item:string)=>fetchOneAccounts(item))
      const promiseResponse:any = await Promise.all(temp);
      const accountIdsOptions = promiseResponse.map((item:any)=>item.accountIds).flat();
      showNoAccountMsg(accountIdsOptions,value ?? []);
      setAccountIds(accountIdsOptions);
      const bul3 = (defaultData?.bul3 ?? '').split('|');
      const newSelAccountVal = promiseResponse.filter((item:any)=>!bul3.includes(item.l3)).map((item:any)=>item.accountIds).flat();
      const needToUpdateDbValue = (defaultData?.accountIds ?? '').split('|').filter((value: any) =>
        accountIdsOptions.includes(value),
    );

      const updatedValue = selectAll
        ? accountIdsOptions
        : [...newSelAccountVal, ...needToUpdateDbValue];
      setSelectedAccountsIds(updatedValue);
    }catch{
      setAccountIds([]);
    }finally {
      setLoader("accountLoader",false);
    }
  };

  const handleBUL2Change = async (value: any, selectAll:boolean = false) => {
    setLoader("bul3Loader",true);
    setAccountIds([]);
    setSelectedAccountsIds([]);
    setL3Values([]);
    setL3Selected([]);
    setL2Selected(value);
    try {
      const l3Options = await fetchAllBusinessUnitL3(value);
      const bul2 = (defaultData?.bul2 ?? '').split('|');
      const bul3NewValuesSel = l3Options.filter((item:any)=>!bul2.includes(item.l2)).map((item:any)=>item.l3).flat();
    
      const bul3 = businessUnitL3Parser(l3Options);
      setL3Values(bul3);

      const needToUpdateDbValue = (defaultData?.bul3 ?? '').split('|').filter((value: any) =>
        bul3.includes(value),
    );
      
      const updatedValue = selectAll
        ? bul3
        : [...needToUpdateDbValue, ...bul3NewValuesSel];

      setL3Selected(updatedValue);
      handleBUL3Change(updatedValue, selectAll);
    }catch{
      setL3Values([]);
    }finally {
      setLoader("bul3Loader",false);
    }
    
  };

  const businessUnitL3Parser = (businessUnits:any) =>{
    let L3UnitsArray: string[] = [];
    businessUnits && businessUnits.map((item: any) => {
        for (let items in item.l3) {
          L3UnitsArray.push(item.l3[items]);
        }
      });
      return L3UnitsArray.sort();
  }
  const fetchAllAccounts = async (bul3:any) =>{
    const idtoken:string = await authref.getIdToken();
    const AccountIds = await costCenterApi.fetchAccountIdsByL3(bul3,idtoken);
    if (AccountIds?.message) {
      return [];
    } else {
      return AccountIds;
    }
  }
  const showNoAccountMsg = (account_ids:any,l3Select:any) => {
    if(account_ids.length == 0 && l3Select.length != 0){
      setIsAccountIdNotAvl(true);
    }else{
      setIsAccountIdNotAvl(false);
    }
  }
  const fetchOneAccounts = async (bul3:string) =>{
    const accountIds = await fetchAllAccounts([bul3]);
    return {l3:bul3,accountIds}
  }
  const fetchAllBusinessUnitL3 = async (bul2 :any) =>{
    const businessUnitsl3 = await costCenterApi.fetchAllBusinessUnitsL3(
        bul2,
      );
    return businessUnitsl3;
  }
  //Fetching userDefaults based on emailId
  const {
    value: _userPreferences = [] as any,
    loading: _userPreferencesLoading,
  } = useAsync(async () => {
    const email = (await identityApi.getProfileInfo()).email;
    setLoader("bul2Loader",true);
    setLoader("bul3Loader",true);
    setLoader("accountLoader",true);
    setLoader("contactNameLoader",true);
    setLoader("contactEmailLoader",true);
    setLoader("otherFieldLoader",true);
    setLoader("loaderDropdowns",true);
    const userDefaultValue = await userServiceApi.getUserDefaultValueForInsights(
      {
        email: email,
      },
    );
    const businessUnits = await costCenterApi.fetchBusinessUnitsL2();
    businessUnits && setL2Values(businessUnits);
    setDefaultData(userDefaultValue);
    const bul2 =  userDefaultValue.bul2 ? userDefaultValue.bul2.split('|') : businessUnits;
    setL2Selected(bul2 ?? []);
    setLoader("bul2Loader",false);
    const  businessUnitsl3 = await fetchAllBusinessUnitL3(bul2);
    const bul3 = businessUnitL3Parser(businessUnitsl3);
    setL3Values(bul3 ?? []);
    const bul3Selected =  userDefaultValue.bul3 ? userDefaultValue.bul3.split('|') : bul3;
    setL3Selected(bul3Selected ?? []);
    setLoader("bul3Loader",false);
    const account_ids = await fetchAllAccounts(bul3Selected);
    showNoAccountMsg(account_ids,bul3Selected ?? []);
    setAccountIds(account_ids ?? []);

    const selectedAccount_ids = userDefaultValue.accountIds ? userDefaultValue.accountIds.split('|') : account_ids;
      setSelectedAccountsIds(selectedAccount_ids);
      setLoader("accountLoader",false);
    userDefaultValue.app_ser_id ? setValue('app_ser_id',userDefaultValue.app_ser_id):null;
    userDefaultValue.cost_center_id ? setValue('cost_center_id',userDefaultValue.cost_center_id):null;
    userDefaultValue.gl_id ? setValue('gl_id',userDefaultValue.gl_id):null;
    userDefaultValue.wbs_id ? setValue('wbs_id',userDefaultValue.wbs_id):null;
    userDefaultValue.userName ?  setFormData((prevData: any) => ({
        ...prevData,
        contact_name_selected: userDefaultValue.userName.split('|'),
      })):null;
    userDefaultValue.emailID ? setFormData((prevData: any) => ({
        ...prevData,
        contact_email_selected: userDefaultValue.emailID.split('|'),
      })):null;
    userDefaultValue.csp ? setFormData((prevData: any) => ({
      ...prevData,
      cspSelected: userDefaultValue.csp.split('|'),
    })):null;
    userDefaultValue.typesOfDeployment ? setFormData((prevData: any) => ({
      ...prevData,
      typesOfDeploymentSelected: userDefaultValue.typesOfDeployment.split('|'),
    })):null;
    userDefaultValue.typesOfAccount ? setFormData((prevData: any) => ({
      ...prevData,
      typesOfAccountSelected: userDefaultValue.typesOfAccount.split('|'),
    })):null;
    userDefaultValue.status ? setFormData((prevData: any) => ({
      ...prevData,
      statusSelected: userDefaultValue.status.split('|'),
    })):null;

    setLoader("contactNameLoader",false);
    setLoader("contactEmailLoader",false);
    setLoader("otherFieldLoader",false);
    setLoader("loaderDropdowns",false);
    return userDefaultValue;
  }, []);

  function filterNotNullAndBlank(values:any) {
    return values.filter((value:any) => value !== "NULL" && value.trim() !== "");
  }
  
  const fetchUserNameEmail = async () => {
    setLoader("contactNameLoader",true);
    setLoader("contactEmailLoader",true);
    setLoader("loaderDropdowns",true);
    const idtoken:string = await authref.getIdToken();
    const businessUnits = await costCenterApi.fetchAllCamAccounts(idtoken);
    const names = [];
    const emails = [];
    if (businessUnits?.error) {
      return [];
    }
    if(defaultSetDataCon && businessUnits.length > 0){
      defaultSetDataCon.current = false
      let uniqueStatusValues = [...new Set(filterNotNullAndBlank(businessUnits.map((unit:any) => unit.status)))];
      let uniqueCSPValues = [...new Set(filterNotNullAndBlank(businessUnits.map((unit:any) => unit.csp)))];
      let uniqueDeploymentModelValues = [...new Set(filterNotNullAndBlank(businessUnits.map((unit:any) => unit.deployment_model)))];
      let uniqueAccountTypeValues = [...new Set(
        businessUnits
          .filter((unit:any) => unit.account_type !== 'NULL' && unit.account_type !== '' && unit.account_type !== 'Nonprod' && unit.account_type !== 'Sandbox')
          .map((unit:any) => unit.account_type)
      )];
        setFormData((prevData: any) => ({
          ...prevData,
          typesOfAccount: uniqueAccountTypeValues.sort(),
          typesOfDeployment: uniqueDeploymentModelValues.sort(),
          csp: uniqueCSPValues.sort(),
          status: uniqueStatusValues.sort(),
        }));
    }
    setLoader("loaderDropdowns",false);
    for (const item of businessUnits) {
      const contacts = item.contacts || [];
      for (const contact of contacts) {
        const name = contact.name;
        const email = contact.email;
        if (name && email) {
          const duplicateIndex = names.findIndex((existingName:any) => existingName === name);
          if (duplicateIndex === -1) {
            names.push(name);
            emails.push(email);
          }
        }
      }
    }
    const sortedNames = names && names.sort((a, b) => {
      return a.localeCompare(b, undefined, {sensitivity: 'base'});
    });
    const sortedEmails = emails && emails.sort((a, b) => {
      return a.localeCompare(b, undefined, {sensitivity: 'base'});
    });
    setFormData((prevData: any) => ({
      ...prevData,
      contact_name: sortedNames,
    }));
    setFormData((prevData: any) => ({
      ...prevData,
      contact_email: sortedEmails,
    }));
    setLoader("contactNameLoader",false);
    setLoader("contactEmailLoader",false);
    return {
      names: sortedNames,
      emails: sortedEmails
    };
  };

  React.useEffect(()=>{
    fetchUserNameEmail();
  },[]);

  const handleCancelBtn = async () => {

    const defaultBul2 = defaultData.bul2 ? defaultData.bul2.split('|') : l2Values;
      handleBUL2Change(defaultBul2);
      setL2Selected(defaultBul2);

    defaultData.bul3 ? setL3Selected(defaultData.bul3.split('|')) : setL3Selected(l3Values);
    defaultData.accountIds ?  setSelectedAccountsIds(defaultData.accountIds.split('|')) : setSelectedAccountsIds(AccountIds); 
    defaultData.userName ? setFormData((prevData: any) => ({
            ...prevData,
            contact_name_selected: defaultData.userName.split('|'),
    })) :setFormData((prevData: any) => ({
            ...prevData,
            contact_name_selected: [],
          }));

    defaultData.emailID ? setFormData((prevData: any) => ({
            ...prevData,
            contact_email_selected: defaultData.emailID.split('|'),
    })) :setFormData((prevData: any) => ({
            ...prevData,
            contact_email_selected: [],
          }));
      const csp = defaultData.csp ? defaultData.emailID.split('|') : [];
    const typeOfDeploy = defaultData.typesOfDeployment ? defaultData.typesOfDeployment.split('|') : []
    const typeOfAccount = defaultData.typesOfAccount ? defaultData.typesOfAccount.split('|') : []
    const status = defaultData.status ? defaultData.status.split('|') : []
      setFormData((prevData: any) => ({
        ...prevData,
        cspSelected: csp,
      typesOfDeploymentSelected:typeOfDeploy,
      typesOfAccountSelected:typeOfAccount,
      statusSelected:status
      }));
    defaultData.app_ser_id ? setValue('app_ser_id',defaultData.app_ser_id): setValue('app_ser_id',"");
    defaultData.cost_center_id ? setValue('cost_center_id',defaultData.cost_center_id):setValue('cost_center_id',"");
    defaultData.gl_id ? setValue('gl_id',defaultData.gl_id):setValue('gl_id',"");
    defaultData.wbs_id ? setValue('wbs_id',defaultData.wbs_id):setValue('wbs_id',"");
    setAlert(prevState => ({
      ...prevState,
      open: true,
      message: 'Preferences Cancelled Successfully',
      severity: 'success',
    }));
  };

  const handleAlertClose = (reason: any) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlert(prevState => ({ ...prevState, open: false }));
  };

  const {
    handleSubmit,
    control,
    setValue,
  } = useForm<CloudAccountFilterForm>({
    mode: 'onChange',
    defaultValues: {
      app_ser_id: '',
      gl_id: '',
      cost_center_id: '',
      wbs_id: '',
      csp:[],
      status:[],
      typesOfDeployment:[],
      typesOfAccount:[],
    },
  });
  const [{ value: _data }, handleSaveToDb] = useAsyncFn(
    async (params: CloudAccountFilterForm) => {
        const email = (await identityApi.getProfileInfo()).email;
        params.bul3 = l3Selected.join('|');
        params.bul2 = l2Selected.join('|');
        params.accountIds = SelectedAccountsIds.join('|');
        params.userName = formData.contact_name_selected.join('|');
        params.emailID = formData.contact_email_selected.join('|');
        params.csp = formData.cspSelected.join('|');
        params.status = formData.statusSelected.join('|');
        params.typesOfDeployment = formData.typesOfDeploymentSelected.join('|');
        params.typesOfAccount = formData.typesOfAccountSelected.join('|');
        const finalData = {
            "email": email,
            "rowsData":params
        };
        setDefaultData(finalData.rowsData);
        await userServiceApi.saveUserPreferenceData(finalData);
        setAlert(prevState => ({
          ...prevState,
          open: true,
          message: 'Preferences Saved Successfully',
          severity: 'success',
        }));
    },
    [l2Selected, l3Selected, SelectedAccountsIds,formData.contact_name_selected,formData.contact_email_selected,formData.cspSelected,formData.statusSelected,formData.typesOfDeploymentSelected,formData.typesOfAccountSelected],
  );
  const handleChange = (value: any, name: string) => {
    setFormData((prevData: any) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleClearAllBtn = async () => {
    try {
      setDisableClrCancel(true);
      setFormData((prevData: any) => ({
        ...prevData,
        contact_name_selected: [],
        contact_email_selected: [],
        cspSelected: [],
        typesOfDeploymentSelected: [],
        typesOfAccountSelected: [],
        statusSelected: [],
      }));

      setValue('app_ser_id', '');
      setValue('cost_center_id', '');
      setValue('gl_id', '');
      setValue('wbs_id', '');
      await handleBUL2Change(l2Values, true);
      setAlert(prevState => ({
        ...prevState,
        open: true,
        message: 'All Preferences Cleared',
        severity: 'success',
      }));
    } catch (e) {
      console.error(e);
    } finally {
      setDisableClrCancel(false);
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid container xs={12} justifyContent="space-between">
      </Grid>
      <form onSubmit={handleSubmit(handleSaveToDb)}>
        <Grid
          container
          xs={12}
          justifyContent="space-between"
          data-testid="heading"
        >
          <Grid item xs={3}>
            <h3 className={classes.headerLabel}>Chofer User Defaults</h3>
          </Grid>
          <Grid item xs={9}>
            <h3 className={classes.buttonHeader}>
              <Button
                variant="outlined"
                className={classes.tertiaryButton}
                data-testid="clearAllButton"
                onClick={handleClearAllBtn}
                disabled={disableClrCancel}
              >
                Clear All
              </Button>
              <Button
                variant="outlined"
                className={classes.secondaryButton}
                data-testid="cancelButton"
                color='secondary'
                onClick={handleCancelBtn}
              >
                Cancel
              </Button>

              <Button
                disabled={SelectedAccountsIds.length == 0}
                className={classes.primaryButton}
                variant="contained"
                data-testid="saveButton"
                color='primary'
                type="submit"
              >
                Save
              </Button>

            </h3>
          </Grid>
          <Grid
            container
            xs={6}
            className={classes.fieldContainer}
          > 
            <Grid item xs={4} className={classes.fieldLabel}>  
              Business Unit L2
            </Grid>
            <Grid item xs={3} data-testid="bul2option">
              <LimitTagsUserDefault
                businessUnitValues={l2Values}
                setBusinessUnitSelected={handleBUL2Change}
                areBUOptionsLoading={customLoader.bul2Loader}
                buSelected={l2Selected}
                name="Business Unit L2"
                disable="bul2"
                //disabled={customLoader.bul3Loader || customLoader.accountLoader}
              />
            </Grid>
          </Grid>
          <Grid
            container
            xs={6}
            className={classes.fieldContainer}
          >
            <Grid item xs={4} className={classes.fieldLabel}>
              Business Unit L3
            </Grid>
            <Grid item xs={3} data-testid="bul3option">
              <LimitTagsUserDefault
                businessUnitValues={l3Values}
                setBusinessUnitSelected={handleBUL3Change}
                areBUOptionsLoading={customLoader.bul3Loader}
                buSelected={l3Selected}
                name="Business Unit L3"
                disable="bul3"
                //disabled={customLoader.bul3Loader || customLoader.accountLoader}
              />
            </Grid>
          </Grid>
          <Grid
            container
            xs={6}
            className={classes.fieldContainer}
          >
            <Grid item xs={4} className={classes.fieldLabel}>
              Account IDs
            </Grid>
            <Grid item xs={3} data-testid="accountIdsoption"> 
              <LimitTagsUserDefault
                businessUnitValues={AccountIds}
                setBusinessUnitSelected={setSelectedAccountsIds}
                areBUOptionsLoading={customLoader.accountLoader}
                buSelected={SelectedAccountsIds}
                name="Account Name/ID"
                disable="acct"
                //disabled={customLoader.bul3Loader || customLoader.accountLoader}
              />
              <Typography
                className={classes.fieldLabelWarn}
              >
                {isAccountIdNotAvl ? 'No Account IDs found' : ''}
              </Typography>
            </Grid>
          </Grid>
          {fields &&
            fields.map((item: any) => {
              return (
                <>
                  {item.field_type == 'single' ? (
                    <Grid
                      container
                      xs={6}
                      className={classes.fieldContainer}
                    >
                      <Grid item xs={4} className={classes.fieldLabel}>
                        {item.field_label}
                      </Grid>
                      <Grid item xs={3}>
                        <Controller
                          control={control}
                          name={item.field_key}
                          render={({ field: { value, onChange } }: any) => (
                            <TextField
                              placeholder=""
                              value={value}
                              style={{width: "300px"}}
                              onChange={onChange}
                              variant="outlined"
                              InputProps={{
                                endAdornment: (
                                  <React.Fragment>
                                    {' '}
                                    {customLoader.otherFieldLoader && (
                                      <CircularProgress  size={20} />
                                    ) }
                                  </React.Fragment>
                                ),
                              }}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  ) : null}
                </>
              );
            })}
          <Grid
            container
            xs={6}
            className={classes.fieldContainer}
          >
            <Grid item xs={4} className={classes.fieldLabel}>
              Contact Name
            </Grid>
            <Grid item xs={3} data-testid="contactNames">
              <LimitTagsUserDefault
                businessUnitValues={formData.contact_name}
                setBusinessUnitSelected={(val: any) =>
                  handleChange(val, 'contact_name_selected')
                }
                areBUOptionsLoading={customLoader.contactNameLoader}
                buSelected={formData.contact_name_selected}
                name="Contact Name"
                disable="contName"
              />
            </Grid>
          </Grid>
          <Grid
            container
            xs={6}
            className={classes.fieldContainer}
          >
            <Grid item xs={4} className={classes.fieldLabel}>
              Contact Email
            </Grid>
            <Grid item xs={3} data-testid='contactEmails'>
              <LimitTagsUserDefault
                businessUnitValues={formData.contact_email}
                setBusinessUnitSelected={(val: any) =>
                  handleChange(val, 'contact_email_selected')
                }
                areBUOptionsLoading={customLoader.contactEmailLoader}
                buSelected={formData.contact_email_selected}
                name="Contact Email"
                disable="contEmail"
              />
            </Grid>
          </Grid>
          <Grid
            container
            xs={6}
            className={classes.fieldContainer}
          >
            <Grid item xs={4} className={classes.fieldLabel}>
              Cloud Service Provider
            </Grid>
            <Grid item xs={3} data-testid='csp'>
              <LimitTagsUserDefault
                businessUnitValues={formData.csp}
                setBusinessUnitSelected={(val: any) =>
                  handleChange(val, 'cspSelected')
                }
                areBUOptionsLoading={customLoader.loaderDropdowns}
                buSelected={formData.cspSelected}
                name="CSP"
                disable="csp"
              />
            </Grid>
          </Grid>
          <Grid
            container
            xs={6}
            className={classes.fieldContainer}
          >
            <Grid item xs={4} className={classes.fieldLabel}>
              Types Of Deployment
            </Grid>
            <Grid item xs={3} data-testid='typeOfDeployment'>
              <LimitTagsUserDefault
                businessUnitValues={formData.typesOfDeployment}
                setBusinessUnitSelected={(val: any) =>
                  handleChange(val, 'typesOfDeploymentSelected')
                }
                areBUOptionsLoading={customLoader.loaderDropdowns}
                buSelected={formData.typesOfDeploymentSelected}
                name="Types Of Deployment"
                disable="typesOfDeployment"
              />
            </Grid>
          </Grid>
          <Grid
            container
            xs={6}
            className={classes.fieldContainer}
          >
            <Grid item xs={4} className={classes.fieldLabel}>
            Types Of Account
            </Grid>
            <Grid item xs={3} data-testid='typeOfAccount'>
              <LimitTagsUserDefault
                businessUnitValues={formData.typesOfAccount}
                setBusinessUnitSelected={(val: any) =>
                  handleChange(val, 'typesOfAccountSelected')
                }
                areBUOptionsLoading={customLoader.loaderDropdowns}
                buSelected={formData.typesOfAccountSelected}
                name="Types Of Account"
                disable="typesOfAccount"
              />
            </Grid>
          </Grid>
          <Grid
            container
            xs={6}
            className={classes.fieldContainer}
          >
            <Grid item xs={4} className={classes.fieldLabel}>
              Account Status
            </Grid>
            <Grid item xs={3} data-testid='accountStatus'>
              <LimitTagsUserDefault
                businessUnitValues={formData.status}
                setBusinessUnitSelected={(val: any) =>
                  handleChange(val, 'statusSelected')
                }
                areBUOptionsLoading={customLoader.loaderDropdowns}
                buSelected={formData.statusSelected}
                name="Account Status"
                disable="status"
              />
            </Grid>
          </Grid>
          <Grid
            container
            xs={6}
            className={classes.fieldContainer}
          >
            <Grid item xs={4} className={classes.fieldLabel}>
              Application Service ID
            </Grid>
            <Grid item xs={3} data-testid='app_ser_id'>
                <Controller
                    control={control}
                    name="app_ser_id"
                    render={({ field: { value, onChange } }: any) => (
                      <TextField
                        placeholder=""
                        value={value}
                        style={{width: "300px"}}
                        onChange={onChange}
                        InputProps={{
                          endAdornment: (
                            <React.Fragment>
                              {' '}
                              {customLoader.otherFieldLoader && (
                                <CircularProgress  size={20} />
                              ) }
                            </React.Fragment>
                          ),
                        }}
                        variant="outlined"
                      />
                    )}
                  />
            </Grid>
          </Grid>
        </Grid>
      </form>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        className={classes.clearMsg}
        open={alert.open}
        autoHideDuration={5000}
        onClose={handleAlertClose}
      >
        <Alert onClose={handleAlertClose} severity={alert.severity}>
          <div className={classes.MsgDesc}>{alert.message}</div>
        </Alert>
      </Snackbar>
    </Grid>
  );
};
