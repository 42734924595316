
/*
 * Copyright 2021 Spotify AB
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

// import React from 'react';
import React, { useState } from 'react';
import {
  //Box,
  //Chip,
  Divider,
  ListItem,
  ListItemText,
  makeStyles,
  // Typography,
} from '@material-ui/core';
import { Link } from '@backstage/core-components';

const useStyles = makeStyles({
  flexContainer: {
    flexWrap: 'wrap',
    display: 'block',
  },
  itemText: {
    width: '100%',
    // marginBottom: '1rem',
  },

  container_readmore: {
    position: 'absolute',
    top: '10%',
    left: '23%',
    width: '50%',
  },
  text_readmore: {
    display: 'inline',
    width: '100%',
  },

  readHide: {
    color: 'red',
    cursor: 'pointer',
  },
});

const convertTitleCase = (str: any) => {
  let type = '';
  if (str.documentType) {
    type = str.documentType;
  }
  if (str.type) {
    type = str.type;
  }

  if (type === 'techdocs') {
    return 'TechDocs';
  } else if (type === 'blueprints') {
    return 'Blueprints';
  } else if (type === 'training') {
    return 'Training';
  } else if (type === 'cost-center') {
    return 'Cost Center';
  } else if (type === 'blogs') {
    return 'Blogs';
  } else if (type === 'news' || type === 'aws-feed' || type === 'gcp-feed') {
    const strFormat = 'Home - ' + type.charAt(0).toUpperCase() + type.slice(1);
    return strFormat;
  } else if (type === 'faq') {
    return 'FAQs';
  }

  return;
};
const ReadMore = ({ searchText }: any) => {
  //const text = searchText;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <div className="text_readmore">
      {isReadMore
        ? searchText.slice(0, 310)
        : searchText.length < 1190
        ? searchText
        : searchText
            .slice(0, 1190)
            .substring(0, searchText.slice(0, 1190).lastIndexOf(' '))}
      {searchText.length > 310 ? (
        <span
          onClick={toggleReadMore}
          className="readHide"
          style={{ color: '#1059CD', cursor: 'pointer' }}
        >
          {isReadMore ? '       ...read more' : '        show less'}
        </span>
      ) : (
        ''
      )}
    </div>
  );
};

export const CatalogResultListItem = ({ result, singleData }: any) => {
  const classes = useStyles();
  const title = singleData ? result.title : result?.subTitle || result.title;
//if (result?.location.indexOf("#") !== -1)
//return null
  return (
    // new formatting -- uncomment once bugs are fixed
    <div>
      <ListItem alignItems="flex-start" className={classes.flexContainer}>
        <Link to={result.location}>
          <div>
            {(result.documentType || result.type) &&
              (result.documentType !== 'service-catalog' ||
                result.type !== 'service-catalog') && (
                <ListItemText
                  className={classes.itemText}
                  primaryTypographyProps={{ variant: 'h6' }}
                  primary={
                    <>
                      <span style={{ color: 'black' }}>
                        {title} | In
                      </span>{' '}
                      <span style={{ color: '#EB0A1E' }}>
                        {convertTitleCase(result)}
                      </span>
                    </>
                  }
                />
              )}
          </div>
        </Link>
        <ReadMore searchText={result.text}></ReadMore>
      </ListItem>
      <Divider component="li" />
    </div>
   
  );
};