/*
 * Copyright 2020 Spotify AB
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {
  Entity,
  RELATION_OWNED_BY,
  RELATION_PART_OF,
} from '@backstage/catalog-model';
import { getEntityRelations } from '@backstage/plugin-catalog-react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { TMNATheme } from 'tmna-theme';
import { CatalogEntityRefLinks } from 'tmna-ui-kit';
import { AboutField } from './AboutField';
import { EntityRefNames } from '../EntityRefNames';

const useStyles = makeStyles<TMNATheme>(() => ({
  description: {
    wordBreak: 'break-word',
  },
}));

type Props = {
  entity: Entity;
};

export const AboutContent = ({ entity }: Props) => {
  const classes = useStyles();
  const isSystem = entity.kind.toLocaleLowerCase('en-US') === 'system';
  const isDomain = entity.kind.toLocaleLowerCase('en-US') === 'domain';
  const isResource = entity.kind.toLocaleLowerCase('en-US') === 'resource';
  const isComponent = entity.kind.toLocaleLowerCase('en-US') === 'component';
  const partOfSystemRelations = getEntityRelations(entity, RELATION_PART_OF, {
    kind: 'system',
  });
  const partOfComponentRelations = getEntityRelations(
    entity,
    RELATION_PART_OF,
    {
      kind: 'component',
    },
  );
  const partOfDomainRelations = getEntityRelations(entity, RELATION_PART_OF, {
    kind: 'domain',
  });
  const ownedByRelations = getEntityRelations(entity, RELATION_OWNED_BY);

  return (
    <Grid container spacing={3}>
      <AboutField label="Description" gridSizes={{ xs: 12 }}>
        <Typography
          variant="body2"
          paragraph
          className={classes.description}
          data-testid="component-description"
        >
          {entity?.metadata?.description || '–'}
        </Typography>
      </AboutField>
      <AboutField label="Toyota Sys ID" gridSizes={{ xs: 12, sm: 6 }}>
        <Typography
          variant="body2"
          paragraph
          className={classes.label}
          data-testid="toyota-sys-id"
        >
          {entity?.metadata?.toyotaSysId || '–'}
        </Typography>
      </AboutField>
      <AboutField label="App Name" gridSizes={{ xs: 12, sm: 6 }}>
        <Typography
          variant="body2"
          paragraph
          className={classes.label}
          data-testid="app-name"
        >
          {entity?.metadata?.appName || '–'}
        </Typography>
      </AboutField>
      <AboutField label="Repo Owner" gridSizes={{ xs: 12, sm: 6 }}>
        <Typography
          variant="body2"
          paragraph
          className={classes.label}
          data-testid="repo-owner"
        >
          {entity?.metadata?.repoOwner || '–'}
        </Typography>
      </AboutField>
      <AboutField label="Team Name" gridSizes={{ xs: 12, sm: 6 }}>
        <Typography
          variant="body2"
          paragraph
          className={classes.label}
          data-testid="team-name"
        >
          {entity?.metadata?.teamName || '–'}
        </Typography>
      </AboutField>
      <AboutField
        label="App Owner"
        gridSizes={{ xs: 12, sm: 6 }}
        data-testid="app-owner"
      >
        {/*<EntityRefLinks entityRefs={ownedByRelations} defaultKind="group" />*/}
        <Typography variant="body2" component="div">
          <EntityRefNames entityRefs={ownedByRelations || '–'} />
        </Typography>
      </AboutField>
      <AboutField label="Repo Owner Email" gridSizes={{ xs: 12, sm: 6 }}>
        <Typography
          variant="body2"
          paragraph
          className={classes.label}
          data-testid="repo-owner-email"
        >
          {entity?.metadata?.repoOwnerEmail || '–'}
        </Typography>
      </AboutField>

      {isSystem && (
        <AboutField label="Domain" gridSizes={{ xs: 12, sm: 6 }}>
          {partOfDomainRelations.length > 0 ? (
            <CatalogEntityRefLinks
              entityRefs={partOfDomainRelations}
              defaultKind="domain"
              type="secondary"
            />
          ) : null}
        </AboutField>
      )}
      {!isSystem && !isDomain && (
        <AboutField label="System" gridSizes={{ xs: 12, sm: 6 }}>
          {partOfSystemRelations.length > 0 ? (
            <CatalogEntityRefLinks
              entityRefs={partOfSystemRelations}
              defaultKind="system"
              type="secondary"
            />
          ) : null}
        </AboutField>
      )}
      {isComponent && partOfComponentRelations.length > 0 && (
        <AboutField label="Parent Component" gridSizes={{ xs: 12, sm: 6 }}>
          {partOfComponentRelations.length > 0 ? (
            <CatalogEntityRefLinks
              entityRefs={partOfComponentRelations}
              defaultKind="component"
              type="secondary"
            />
          ) : null}
        </AboutField>
      )}
      {!isSystem && !isDomain && (
        <AboutField
          label="Component Type"
          value={entity?.spec?.type as string}
          gridSizes={{ xs: 12, sm: 6 }}
        />
      )}
      {!isSystem && !isDomain && !isResource && (
        <AboutField
          label="Component Lifecycle"
          value={entity?.spec?.lifecycle as string}
          gridSizes={{ xs: 12, sm: 6 }}
        />
      )}
    </Grid>
  );
};
