/*
 * Copyright 2020 Spotify AB
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import {
  Entity,
  CompoundEntityRef,
  RELATION_OWNED_BY,
  RELATION_PART_OF,
  ANNOTATION_EDIT_URL,
  ANNOTATION_VIEW_URL,
} from '@backstage/catalog-model';
import {
  humanizeEntityRef,
  getEntityRelations,
  useEntityList,
  useEntityTypeFilter,
  useStarredEntities,
} from '@backstage/plugin-catalog-react';
import { Box, makeStyles, withStyles } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import GithubIcon from '@material-ui/icons/GitHub';
import { capitalize } from 'lodash';
import React from 'react';
import { CatalogEntityRefLink, EmptyDataMessage } from 'tmna-ui-kit';
import * as columnFactories from './columns';
import {
  CodeSnippet,
  Table,
  TableColumn,
  TableProps,
  WarningPanel,
} from '@backstage/core-components';
import { Star, StarBorder } from '@material-ui/icons';

const PAGE_SIZE_OPTIONS = [10, 20, 30];
const YellowStar = withStyles({
  root: {
    color: '#f3ba37',
  },
})(Star);
const defaultColumns: TableColumn<EntityRow>[] = [
  columnFactories.createNameColumn(),
  // columnFactories.createSystemColumn(),
  columnFactories.createOwnerColumn(),
  columnFactories.createSpecTypeColumn(),
  columnFactories.createSpecLifecycleColumn(),
  columnFactories.createMetadataDescriptionColumn(),
  columnFactories.createTagsColumn(),
];

const useStyles = makeStyles({
  iconContainer: {
    width: 28,
    height: 28,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

type EntityRow = {
  entity: Entity;
  resolved: {
    name: string;
    partOfSystemRelationTitle?: string;
    partOfSystemRelations: CompoundEntityRef[];
    ownedByRelationsTitle?: string;
    ownedByRelations: CompoundEntityRef[];
  };
};

type CatalogTableProps = {
  columns?: TableColumn<EntityRow>[];
};
export const CatalogTable = ({ columns }: CatalogTableProps) => {
  const classes = useStyles();
  const { isStarredEntity, toggleStarredEntity } = useStarredEntities();
  const {
    loading: isEntityListLoading,
    error,
    entities,
    filters,
  } = useEntityList();
  const { loading: isEntityTypeLoading } = useEntityTypeFilter();

  // TODO(timbonicus): remove the title from the CatalogTable once using EntitySearchBar
  const titlePreamble = capitalize(filters.user?.value ?? 'all');
  const actions: TableProps<EntityRow>['actions'] = React.useMemo(
    () => [
      ({ entity }) => {
        const url = entity.metadata.annotations?.[ANNOTATION_VIEW_URL];

        return {
          icon: () => (
            <Box className={classes.iconContainer}>
              <GithubIcon aria-label="View" fontSize="small" />
            </Box>
          ),
          tooltip: 'View',
          disabled: !url,
          onClick: () => {
            if (!url) return;
            window.open(url, '_blank');
          },
        };
      },
      ({ entity }) => {
        const url = entity.metadata.annotations?.[ANNOTATION_EDIT_URL];
        return {
          icon: () => (
            <Box className={classes.iconContainer}>
              <EditIcon aria-label="Edit" fontSize="small" />
            </Box>
          ),
          tooltip: 'Edit',
          disabled: !url,
          onClick: () => {
            if (!url) return;
            window.open(url, '_blank');
          },
        };
      },
      ({ entity }) => {
        const isStarred = isStarredEntity(entity);
        return {
          cellStyle: { paddingLeft: '1em' },
          icon: () => (
            <Box className={classes.iconContainer}>
              {isStarred ? <YellowStar /> : <StarBorder />}
            </Box>
          ),
          tooltip: isStarred ? 'Remove from favorites' : 'Add to favorites',
          onClick: () => toggleStarredEntity(entity),
        };
      },
    ],
    [isStarredEntity, toggleStarredEntity],
  );

  const rows = React.useMemo(
    () =>
      entities.map((entity: any) => {
        const partOfSystemRelations = getEntityRelations(
          entity,
          RELATION_PART_OF,
          {
            kind: 'system',
          },
        );
        const ownedByRelations = getEntityRelations(entity, RELATION_OWNED_BY);

        return {
          entity,
          resolved: {
            name: humanizeEntityRef(entity, {
              defaultKind: 'Component',
            }),
            ownedByRelationsTitle: ownedByRelations
              .map(r => humanizeEntityRef(r, { defaultKind: 'group' }))
              .join(', '),
            ownedByRelations,
            partOfSystemRelationTitle: partOfSystemRelations
              .map(r =>
                humanizeEntityRef(r, {
                  defaultKind: 'system',
                }),
              )
              .join(', '),
            partOfSystemRelations,
          },
        };
      }),
    [entities],
  );

  if (error) {
    return (
      <div>
        <WarningPanel
          severity="error"
          title="Could not fetch catalog entities."
        >
          <CodeSnippet language="text" text={error.toString()} />
        </WarningPanel>
      </div>
    );
  }

  const loading = isEntityListLoading || isEntityTypeLoading;
  columns = [
    {
      title: 'Name',
      field: 'resolved.name',
      highlight: true,
      cellStyle: { minWidth: '150px' },
      render: ({ entity, resolved }) => (
        <CatalogEntityRefLink
          entityRef={entity}
          defaultKind="Component"
          type="secondary"
          children={resolved.name}
        />
      ),
    },
    { title: 'Owner', field: 'resolved.ownedByRelationsTitle' },
    { title: 'Repo Owner', field: 'entity.metadata.repoOwner' },
    { title: 'LifeCycle', field: 'entity.spec.lifecycle' },
    { title: 'Description', field: 'entity.metadata.description' },
    { title: 'Type', field: 'entity.spec.type' },
    { title: 'Team Name', field: 'entity.metadata.teamName'},
  ];
  for (const entity of entities) {
    //columns[0].render = entity;
    entity.metadata.name
      ? (entity.metadata.name = entity.metadata.name)
      : (entity.metadata.name = '-');

    entity.metadata.repoOwner
      ? (entity.metadata.repoOwner = entity.metadata.repoOwner)
      : (entity.metadata.repoOwner = '-');

    entity.metadata.description
      ? (entity.metadata.description = entity.metadata.description)
      : (entity.metadata.description = '-');

    entity.metadata?.teamName
      ?(entity.metadata.teamName = entity.metadata.teamName)
      : (entity.metadata.teamName = '-');
    
  }

  return (
    <Table<EntityRow>
      isLoading={loading}
      columns={columns || defaultColumns}
      options={{
        paging: true,
        actionsColumnIndex: -1,
        showEmptyDataSourceMessage: !loading,
        padding: 'dense',
        pageSize: PAGE_SIZE_OPTIONS[0],
        pageSizeOptions: PAGE_SIZE_OPTIONS,
        emptyRowsWhenPaging: false,
        actionsCellStyle: { padding: '10px 16px' },
      }}
      localization={{
        body: {
          emptyDataSourceMessage: <EmptyDataMessage />,
        },
      }}
      title={titlePreamble}
      data={rows}
      actions={actions}
      style={{ boxShadow: '0 1px 2px 0 rgb(0 0 0 / 20%)', flex: 1,width:"100%"}}
    />
  );
};

CatalogTable.columns = columnFactories;
