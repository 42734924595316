/*
 * Copyright 2020 Spotify AB
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import type { Transformer } from './transformer';

type OnCssReadyOptions = {
  docStorageUrl: string;
  onLoading: (dom: Element) => void;
  onLoaded: (dom: Element) => void;
};

export const onCssReady = ({
  docStorageUrl,
  onLoading,
  onLoaded,
}: OnCssReadyOptions): Transformer => {
  return dom => {
    const cssPages = Array.from(
      dom.querySelectorAll('head > link[rel="stylesheet"]'),
    ).filter(elem => elem.getAttribute('href')?.startsWith(docStorageUrl));

    let count = cssPages.length;

    if (count > 0) {
      onLoading(dom);
    }

    cssPages.forEach(cssPage =>
      cssPage.addEventListener('load', () => {
        count -= 1;

        if (count === 0) {
          onLoaded(dom);
        }
      }),
    );

    return dom;
  };
};
