import React from 'react';
import {CompoundEntityRef} from '@backstage/catalog-model';
import {Box} from '@material-ui/core';

export interface EntityRefNamesProps  {
    entityRefs: CompoundEntityRef[],
}

export const EntityRefNames = ({
   entityRefs,
}: EntityRefNamesProps) => (
    <>
        {entityRefs.map((r, i) => (
            <React.Fragment key={i}>
                {i > 0 && ', '}
                <Box>{r.name}</Box>
            </React.Fragment>
        ))}
    </>
);