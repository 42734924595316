import {makeStyles, Typography} from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(theme => ({
  root: {
    textAlign: 'left',
    margin: theme.spacing(2),
    display: 'inline-block',
    fontSize: 14,
  },
  label: {
    fontWeight: 600,
    marginBottom: 4,
  },
}));

type HeaderLabelContentProps = {
  value: React.ReactNode;
  className: string;
};

type HeaderLabelProps = {
  label: string;
  value?: HeaderLabelContentProps['value'];
};

export const HeaderLabel = ({ label, value }: HeaderLabelProps) => {
  const classes = useStyles();

  return (
    <span className={classes.root}>
      <Typography variant="body2" className={classes.label}>{label}</Typography>
      {value}
    </span>
  );
};
